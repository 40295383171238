import './App.css';
import { Routes, Route, Link, Navigate } from "react-router-dom";
import Hamburger from './components/hamburgerbtn/component';
import { useEffect, useState } from 'react';
import AboutMe from './sites/AboutMe/AboutMe';
import Error from './sites/Error/Error';

function App() {




  function start() {
    (document.querySelector("nav") as HTMLElement).style.transform = "translate(0%, 0)";
  }

  function close() {
    (document.querySelector("nav") as HTMLElement).style.transform = "";
  }

  function toggleImpressum(){
    (document.querySelector("dialog") as HTMLElement).style.display = impressum ? "none" : "block";
    setimpressum(!impressum);
  }

  const [scrolling, setScrolling] = useState(false);
  const [impressum, setimpressum] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const onScroll = (e:any) => {
      let height = window.innerHeight;
      let scroll = e.target.documentElement.scrollTop;
      setScrollTop(scroll);
      setScrolling(scroll + 55 >= height);
    };
    window.addEventListener("scroll", onScroll);
  }, [scrollTop]);

  useEffect(() => {
    (document.querySelector("header") as HTMLElement).style.transform = scrolling ? "translate(0, 0)" : "";
    //(document.querySelector("footer") as HTMLElement).style.transform = scrolling ? "translate(0, 0)" : "";
  },[scrolling]);
  
  return (
    <>
      <main>
        <Routes>
          <Route
              path="/"
              element={<Navigate to="/AboutMe" replace />}
          />
          <Route path="/AboutMe" element={<AboutMe />}/>
          <Route path="/Error" element={<Error />}/>
          <Route
              path="*"
              element={<Navigate to="/Error" replace />}
          />
        </Routes>
      </main>
      <header>
      </header>
      <footer>
        <div id = "Impressum" onClick={toggleImpressum}>
          Impressum
        </div>
      </footer>
      <nav>
        <Link to="AboutMe" className="navElement">About Me</Link>
        <div className="navElementHover navElement">
          <div className="navHoverTitle">Projekte</div>
          <div className="navHoverContent">
            <Link to="Projekte/KoKi" className="navHoverContentElement">KoKi</Link>
            <Link to="Projekte/LevenSearch" className="navHoverContentElement">Leven Search</Link>
            <Link to="Projekte" className="navHoverContentElement">Alle Projekte</Link>
          </div>
        </div>
        <Link to="akademisch" className="navElement">Akademisch</Link>
        <Link to="Karriere" className="navElement">Karriere</Link>
      </nav>
      <Hamburger start={start} close={close}/>
      <dialog open>
        <h1>Angaben gemäß § 5 TMG</h1>
        <div className='parent'>
          <div className='div1'>
            <b>Seitenbetreiber & Verantwortlicher für den Inhalt nach § 55 Abs. 2 RStV</b>
          </div>
          <div className='div2'>
            Name:
          </div>
          <div className='div3'>
            Kilian Koch
          </div>
          <div className='div4'>
            Anschrift:
          </div>
          <div className='div5'>
            Königstraße 14-16, Aachen 52064 Deutschland
          </div>
          <div className='div6'>
            <b>Kontaktdaten</b>
          </div>
          <div className='div7'>
            E-Mail:
          </div>
          <div className='div8'>
            kilian_koch@gmx.de
          </div>
          <div className='div9'>
            TEL:
          </div>
          <div className='div10'>
            +49(0)15225244552 
          </div>
        </div>
      </dialog>
    </>
  );
}

export default App;
