import './css/Error.css';
import { Link } from 'react-router-dom'
import { useState } from 'react';

function getRandomInt(max : number) {
  return Math.floor(Math.random() * max);
}


function Error() {

  //const location = useLocation();
  const Emojis = ["😒", "😞", "😔", "😟", "😕", "🙁", "☹️", "😣", "😖", "😫", "😩", "🥺", "😢", "😭", "😤", "😠", "🤯",
                  "🥶", "😱", "😨", "😰", "😥", "😓","🤔","🤥", "😶", "😐", "😑", "😬", "🙄", "😯", "😦", "😧", "😮", "😲"];

  const [Emote] = useState(Emojis[getRandomInt(Emojis.length)]);
  
  return (
      <>
        <div className='Error'>
          <div className='ErrorHead'>
            <div className='ErrorHeadLeft'>
              <div className='ErrorHeadAlignLeft'>
                404
              </div>
              <div className='ErrorHeadAlignRight'>
                ERROR
              </div>
            </div>
            <div className='ErrorHeadRight'>
              {Emote}
            </div>
          </div>
          <div className='ErrorFoot'>
            Diese Seite existiert nicht.
            <Link to={'/'}>
              <button className='BackHome'>
                Home
              </button>
            </Link>
          </div>
        </div>
      </>
  );
}

export default Error;
