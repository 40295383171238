import './css/component.css';
import PP from './media/pp.jpg';

interface TitleText {
    title: string,
    subtitle?: string
}

function TitelArticle(TitleText : TitleText) {
  return (
    <article className='title_article'>
        <div className='background_titel_article'>

        </div>
        <div className='title_pp'>
          <img src={PP} alt='PP'></img>
        </div>
        <div className='title_frame'>
            <div className='title_text'>
                {TitleText.title}
            </div>
            <div className='title_underscore'>
            {TitleText.subtitle}
            </div>
        </div>
    </article>
  );
}

export default TitelArticle;
