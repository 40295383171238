import './css/component.css';
import React, { useEffect, useState } from 'react';
import First from './media/First.jpg';
import Second from './media/Second.jpg';

interface Content {
    title: string,
    text1: string,
    text2: string
}

function ViewArticle(Content : Content) {
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const onScroll = (e:any) => {
      let height = window.innerHeight;
      let scroll = e.target.documentElement.scrollTop;
      setScrollTop(scroll);
      setScrolling(scroll + 55 >= height);
    };
    window.addEventListener("scroll", onScroll);
  }, [scrollTop]);

  useEffect(() => {
    (document.querySelector("header") as HTMLElement).style.transform = scrolling ? "translate(0, 0)" : "";
  },[scrolling]);

  return (
    <article className='ViewArticle'>
      <div className='ViewArticleTitle'>
        {Content.title}
      </div>
      <div className='ViewArticleContent'>
        <div className='ViewArticleDummy'>
          <div className='ViewSideStyle'>

          </div>
          <div className='ViewFirstTriangle'>
          </div>
          <div className='ViewArticleImgFirst'>
            <img src = {First} height="0" alt='Error'></img>
          </div>
        </div>
        <div className='ViewArticleText'>
          <div className='ViewArticleTextContent'>
            {Content.text1}
          </div>
        </div>
      </div>
      <div className='ViewArticleContent'>
        <div className='ViewArticleText'>
          <div className='ViewArticleTextContent'>
            {Content.text2}
          </div>
        </div>
        <div className='ViewArticleDummy'>
          <div className='ViewSideStyle'>
          </div>
          <div className='ViewSecondTriangle'>
          </div>
          <div className='ViewArticleImgSecond'>
            <img src = {Second} height="0" alt='Error'></img>
          </div>
        </div>
      </div>
    </article>
  );
}

export default ViewArticle;
