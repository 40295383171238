import './css/component.css';
import React, { useState } from 'react';

interface ClickEvents {
    start: () => void,
    close: () => void
}

function HamburgerBTN(ClickEvents : ClickEvents) {
  const [isPressed, setPressed] = useState(false);
  const triggerBtn = () => {
    if(isPressed){
      ClickEvents.close()
    }else{
      ClickEvents.start()
    }
    setPressed(!isPressed); 
   };

  return (
    <div className={'hamburger' + (isPressed ? " open" : "")} onClick={triggerBtn} data-pressed={0}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}

export default HamburgerBTN;
